import React from 'react';
import { useApolloClient } from '@apollo/client';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { trackSocialButtonClicked } from 'src/common/tracking/trackingUtils';
import { OAuthDialog } from 'src/components/Dialog/OAuthDialog';
import { SignInSource } from 'src/components/LoginModal/ExternalSignInButtons';
import { IDENTITY_TYPES } from 'src/modules/Settings/Constants';

import { TRACKING_CLASSNAMES } from '../common/constants';
import { loginWithFacebook } from '../modules/Session/Actions';
import { FacebookButton } from '../styles/components/buttons';

const Icon = styled.i`
  background-image: url('/images/onboarding/facebook-white.png');
  margin-top: 10px;
`;

type LoginWithFacebookButtonProps = {
  className?: string;
  gtmData: {
    signup: string;
    login: string;
  };
  signInSource: SignInSource;
};

const LoginWithFacebookButton = ({
  className,
  gtmData,
  signInSource,
}: LoginWithFacebookButtonProps) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const apolloClient = useApolloClient();

  return (
    <FacebookButton
      className={classNames(TRACKING_CLASSNAMES.facebookLoginButton, className)}
      id={`${IDENTITY_TYPES.FACEBOOK}-oauth-button`}
      onClick={() =>
        trackSocialButtonClicked({
          type: 'facebook',
          page: router.asPath.includes('signup') ? 'signup' : 'login',
        })
      }
    >
      <Icon />
      <OAuthDialog
        platform={IDENTITY_TYPES.FACEBOOK}
        authAction={(code) =>
          dispatch(loginWithFacebook(code, gtmData, signInSource, apolloClient))
        }
      />
    </FacebookButton>
  );
};

export default LoginWithFacebookButton;
